@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body, head {
  background-color: #d6b4b1;
  color: #d6b4b1;
  background: #d6b4b1; 
  --kf-background-alt: #d6b4b1;
}



.carousel-container {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  overscroll-behavior-y: none;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.post {
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  background-position: center; /* Center the background image */
  background-size: cover;
}

#background {
  position: absolute;
  transition: background-color 1s ease; /* increase transition duration */
}

.transition-background {
  transition: background-color 1s ease;
}

.transition-opacity {
  transition: opacity 1s;
}

.fade-in {
  opacity: 1;
  animation: fade-in 1s ease-in-out;
}

.fade-out {
  opacity: 0;
  animation: fade-out 1s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#button {
  top: 29rem;
}

#creations {
  top: 21.5rem;
}

#gif {
  height: 75vh;
}

#bodytext{
  padding-top: 63vh;
}

#titletext {
  padding-top: 65vh;
}
